import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({palette, breakpoints, shadow, spacing}) => ({
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: spacing(5),
  },
  headcountsTitleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: spacing(8),
    [breakpoints.down('sm')]: {
      paddingBottom: spacing(4),
    },
  },
  consolidatedRevenueLegend: {
    color: palette.secondary.main,
  },
  operatingResultLegend: {
    color: palette.tertiary.main,
  },
  firstGraphContainer: {
    width: 500,
    height: 500,
    maxWidth: '50%',
    [breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  barChartsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    [breakpoints.down('md')]: {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  revenueLegend: {
    fontSize: 16,
    fontFamily: 'CamptonBook',
    color: palette.text.grey,
    paddingTop: spacing(10),
  },
  headcounts: {
    height: 400,
    width: '100%',
    paddingLeft: spacing(20),
    '& line': {
      display: 'none',
    },
    [breakpoints.down('sm')]: {
      marginTop: spacing(7),
      marginBottom: spacing(5),
      paddingLeft: 0,
    },
    [breakpoints.down('md')]: {
      marginTop: spacing(7),
      paddingLeft: spacing(0),
    },
  },
  tooltip: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 320,
    height: 100,
    backgroundColor: 'white',
    borderRadius: spacing(1),
    boxShadow: shadow.default,
    color: palette.text.primary,
  },
  tooltip2: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 50,
    height: 35,
    backgroundColor: 'white',
    borderRadius: spacing(1),
    boxShadow: shadow.default,
    color: palette.text.primary,
  },
  graphContainer: {
    height: 400,
    position: 'absolute',
    top: 200,
    width: '50%',
    zIndex: 4,
    '& line': {
      display: 'none',
    },
    [breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  secondGraph: {
    height: 300,
    position: 'absolute',
    top: 200,
    width: '50%',
    zIndex: 5,
    '& line': {
      display: 'none',
    },
    '& > div > div:nth-last-of-type(1) > div > svg > g > g:nth-of-type(2) > rect':
      {
        height: 244,
        y: '-102px',
      },
    '& > div > div:nth-last-of-type(1) > div > svg > g > g:nth-of-type(3) > rect':
      {
        height: 266,
        y: '-105px',
      },
    '& > div > div:nth-last-of-type(1) > div > svg > g > g:nth-of-type(4) > rect':
      {
        height: 300,
        y: '-53px',
      },
    '& > div > div:nth-last-of-type(1) > div > svg > g > g:nth-of-type(5) > rect':
      {
        height: 319,
        y: '-60px',
      },
    '& > div > div:nth-last-of-type(1) > div > svg > g > g:nth-of-type(6) > rect':
      {
        height: 334,
        y: '-236px',
      },
    '& > div > div:nth-last-of-type(1) > div > svg > g > g:nth-of-type(7) > rect':
      {
        height: 304,
        y: '-145px',
      },
    [breakpoints.down('sm')]: {
      width: '100%',
      '& > div > div:nth-last-of-type(1) > div > svg > g > g:nth-of-type(2) > rect':
        {
          height: 209,
          y: '-96px',
        },
      '& > div > div:nth-last-of-type(1) > div > svg > g > g:nth-of-type(3) > rect':
        {
          height: 228,
          y: '-98px',
        },
      '& > div > div:nth-last-of-type(1) > div > svg > g > g:nth-of-type(4) > rect':
        {
          height: 257,
          y: '-54px',
        },
      '& > div > div:nth-last-of-type(1) > div > svg > g > g:nth-of-type(5) > rect':
        {
          height: 273,
          y: '-59px',
        },
      '& > div > div:nth-last-of-type(1) > div > svg > g > g:nth-of-type(6) > rect':
        {
          height: 287,
          y: '-211px',
        },
      '& > div > div:nth-last-of-type(1) > div > svg > g > g:nth-of-type(7) > rect':
        {
          height: 261,
          y: '-133px',
        },
    },
  },
}))

export default useStyles
