import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({breakpoints, palette, shadow, spacing}) => ({
  container: {
    display: 'flex',
    marginTop: spacing(7.5),
    '& > div': {
      width: '50%',
    },
    [breakpoints.down('md')]: {
      flexDirection: 'column',
      '& > div': {
        width: '100%',
      },
    },
  },
  column: {
    border: `1px solid ${palette.divider}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '&:first-of-type': {
      [breakpoints.up('md')]: {
        borderRight: 0,
      },
      '& > div:first-of-type': {
        borderBottom: `1px solid ${palette.divider}`,
      },
    },
    [breakpoints.down('sm')]: {
      paddingBottom: spacing(7.5),
      '& > div:last-of-type': {
        borderTop: 0,
      },
    },
  },
  chartContainerWrap: {
    [breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  chartContainer: {
    paddingBottom: spacing(5),
    '& > h3, & > ul': {
      margin: '0  10%',
    },
    [breakpoints.down('sm')]: {
      maxWidth: 400,
      margin: 'auto',
    },
    '& > h3': {
      fontSize: 30,
      margin: spacing(7.5, 'auto', 5, 'auto'),
    },
  },
  smallContainer: {
    margin: spacing(0, 2.5),
    height: 200,
    [breakpoints.down('sm')]: {
      height: '30vw',
    },
  },
  largeContainer: {
    height: 400,
    '& > div': {
      margin: '0 auto',
    },
    [breakpoints.down('sm')]: {
      height: '40vw',
      marginBottom: spacing(2.5),
    },
  },
  legende: {
    paddingTop: spacing(3),
    paddingInlineStart: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& > li ': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: spacing(1),
    },
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: spacing(0.5),
    width: '100%',
    margin: '0 !important',
    '& > div': {
      justifyContent: 'space-between',
      width: '100%',
      display: 'flex',
    },
  },
  subSectionTitle: {
    marginTop: spacing(15.25),
    marginBottom: spacing(5),
  },
  smallText: {
    marginTop: spacing(2),
    color: palette.text.grey,
    fontFamily: 'camptonBook',
    fontSize: 16,
  },
  tooltip: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: spacing(7.5),
    height: spacing(5),
    backgroundColor: 'white',
    borderRadius: spacing(1),
    boxShadow: shadow.default,
    color: palette.text.primary,
  },
  valueLastYear: {
    fontFamily: 'CamptonBook',
  },
  barChartsLegend: {
    [breakpoints.down('sm')]: {
      paddingTop: spacing(20),
    },
  },
}))

export default useStyles
