import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({palette, breakpoints, shadow, spacing}) => ({
  container: {
    background: palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    top: spacing(-22.25),
    boxShadow: shadow.large,
    borderRadius: spacing(1, 5),
    width: 'auto',
    padding: spacing(12.5, 25),
    [breakpoints.down('sm')]: {
      padding: spacing(8),
    },
    '& > h3': {fontSize: 30},
    '& > p': {
      fontSize: 18,
      marginTop: spacing(2.5),
    },
  },
  author: {
    marginTop: spacing(4.25),
    display: 'flex',
    justifyContent: 'flex-end',
    '& img': {
      borderRadius: '50%',
    },
  },
  authorInfo: {
    marginLeft: spacing(2.5),
    '& > h4': {
      fontSize: 20,
    },
    '& > p': {
      marginTop: spacing(0.5),
      fontSize: 18,
    },
  },
  linkedInContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: -spacing(10),
    width: '40%',
    '& img': {
      height: 60,
      width: 60,
      borderRadius: '50%',
    },
    '& p': {
      paddingLeft: spacing(3),
    },
    marginLeft: '35vw',
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: spacing(20),
      marginLeft: 0,
      marginTop: -spacing(6),
    },
  },
  nameCeo: {
    margin: 0,
    fontSize: '20px',
    fontFamily: 'CamptonMedium',
    color: '#333333',
  },
  jobCeo: {
    margin: 0,
    paddingTop: spacing(1),
    fontSize: '18px',
    fontFamily: 'CamptonLight',
    color: '#333333',
  },
  icon: {
    transform: 'scale(4)',
    paddingLeft: spacing(0.7),
    marginBottom: -spacing(3),
    [breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
  },
}))

export default useStyles
