import * as R from 'ramda'
import {arrayOf, objectOf, shape} from 'prop-types'
import {line} from 'd3-shape'
import {ResponsiveBar} from '@nivo/bar'
import React from 'react'

import {barChartsPropTypes} from 'helpers/propTypes'
import Section from 'components/UI/Section'
import Title from 'components/UI/Title'

import useIsMobile from 'hooks/useIsMobile'
import useStyles from './styles'

const BarCharts = ({
  keyFiguresTitle,
  keyFiguresRevenuesTitle,
  keyFiguresHeadcountTitle,
  keyFiguresData,
  keyFiguresCurrentOperationalResultsTitle,
}) => {
  const classes = useStyles()
  const isMobile = useIsMobile()

  const barTheme = {
    textColor: '#333333',
    fontSize: '16px',
  }

  const renderRevenuesTitle = () => (
    <div className={classes.titleContainer}>
      <Title
        variant="h3"
        type="mediumTitle"
        fontFamily="book"
        className={classes.title}
      >
        {keyFiguresRevenuesTitle}
      </Title>
      <Title
        variant="h3"
        type="mediumTitle"
        fontFamily="book"
        className={classes.title}
      >
        {keyFiguresCurrentOperationalResultsTitle}
      </Title>
    </div>
  )

  // eslint-disable-next-line react/prop-types
  const Line = ({bars, xScale, yScale}) => {
    const lineGenerator = line()
      .x(d => xScale(d.data.indexValue) + d.width / 2)
      .y(d => yScale(d.data.data.operatingResult))

    return (
      <>
        <path d={lineGenerator(bars)} fill="none" stroke="#da0127" />
        {R.map(
          bar => (
            <circle
              key={bar.key}
              cx={xScale(bar.data.indexValue) + bar.width / 2}
              cy={yScale(bar.data.value)}
              r={3}
              fill="#da0127"
              stroke="#da0127"
              style={{pointerEvents: 'none'}}
            />
          ),
          bars,
        )}
      </>
    )
  }

  const renderOperationalResult = () => (
    <div style={{height: '500px', width: '100%'}}>
      <ResponsiveBar
        width={isMobile ? 350 : 500}
        height={isMobile ? 350 : 400}
        data={keyFiguresData.operatingResult}
        keys={['operatingResult']}
        maxValue={20}
        padding={0.25}
        margin={{
          top: 10,
          bottom: 45,
          right: 30,
          left: 37,
        }}
        indexBy="year"
        colors="transparent"
        axisRight={{
          tickValues: 5,
        }}
        axisLeft={null}
        axisTop={null}
        enableGridY={false}
        axisBottom={null}
        label={null}
        tooltip={input => (
          <div className={classes.tooltip}>
            {R.map(
              revenue =>
                R.equals(revenue.year, input.indexValue) && (
                  <div>
                    <p>
                      <span className={classes.consolidatedRevenueLegend}>
                        {keyFiguresRevenuesTitle}:{' '}
                      </span>
                      {revenue.consolidatedRevenue}
                    </p>
                    <p>
                      <span className={classes.operatingResultLegend}>
                        {keyFiguresCurrentOperationalResultsTitle}:{' '}
                      </span>
                      {input.formattedValue}
                    </p>
                  </div>
                ),
              keyFiguresData.consolidatedRevenues,
            )}
          </div>
        )}
        layers={['grid', 'axes', 'bars', 'markers', 'legends', Line]}
        theme={barTheme}
      />
    </div>
  )

  const renderRevenueBarChart = () => (
    <div style={{height: '500px'}}>
      <ResponsiveBar
        width={isMobile ? 350 : 500}
        height={isMobile ? 350 : 400}
        data={keyFiguresData.consolidatedRevenues}
        keys={['consolidatedRevenue']}
        maxValue={350}
        padding={0.25}
        margin={{
          top: 10,
          bottom: 45,
          right: 30,
          left: 37,
        }}
        indexBy="year"
        label={null}
        colors="#fc990f"
        axisLeft={{
          tickValues: 7,
        }}
        borderRadius={5}
        enableGridY={false}
        layers={['grid', 'axes', 'bars', 'markers', 'legends']}
        theme={barTheme}
      />
    </div>
  )

  const renderHeadcountBarChart = () => (
    <div className={classes.headcounts}>
      <div className={classes.headcountsTitleContainer}>
        <Title variant="h3" type="mediumTitle" fontFamily="book">
          {keyFiguresHeadcountTitle}
        </Title>
      </div>
      <ResponsiveBar
        width={isMobile ? 350 : 500}
        height={isMobile ? 350 : 400}
        data={keyFiguresData.headcounts}
        keys={['headcount']}
        maxValue={3500}
        padding={0.25}
        margin={{
          top: 10,
          right: 10,
          bottom: 45,
          left: 50,
        }}
        enableGridY={false}
        indexBy="year"
        labelTextColor="#fc990f"
        colors="#fc990f"
        axisLeft={{
          tickValues: 8,
        }}
        borderRadius={5}
        tooltip={input => (
          <div className={classes.tooltip2}>{input.formattedValue}</div>
        )}
        layers={['grid', 'axes', 'bars', 'markers', 'legends']}
        theme={barTheme}
      />
    </div>
  )

  return (
    <div>
      <Title variant="h2" type="title">
        {keyFiguresTitle}
      </Title>
      <Section
        hasPaddingSide={false}
        hasVerySmallPadding
        hasPaddingBottom={false}
      >
        <div className={classes.barChartsContainer}>
          <div className={classes.firstGraphContainer}>
            {renderRevenuesTitle()}
            <div className={classes.graphContainer}>
              {renderRevenueBarChart()}
            </div>
            <div className={classes.secondGraph}>
              {renderOperationalResult()}
            </div>
          </div>
          <div>{renderHeadcountBarChart()}</div>
        </div>
      </Section>
    </div>
  )
}

BarCharts.propTypes = barChartsPropTypes

BarCharts.defaultProps = {
  keyFiguresCurrentOperationalResultsTitle: '',
  keyFiguresData: shape({
    breakdown: objectOf(
      shape({
        byGeographicArea: arrayOf(
          shape({
            name: '',
            valueThisYear: '',
            valueLastYear: '',
          }),
        ),
        byIndustry: arrayOf(
          shape({
            name: '',
            valueThisYear: '',
            valueLastYear: '',
          }),
        ),
        byMarketSegment: arrayOf(
          shape({
            name: '',
            valueThisYear: '',
            valueLastYear: '',
          }),
        ),
      }),
    ),
    consolidatedRevenue: arrayOf(
      shape({
        consolidatedRevenue: 0,
        year: 2015,
      }),
    ),
    operatingResult: arrayOf(
      shape({
        operatingResult: 0,
        year: 2015,
      }),
    ),
    headcounts: arrayOf(
      shape({
        headcount: 0,
        year: 2015,
      }),
    ),
  }),
  keyFiguresHeadcountTitle: '',
  keyFiguresRevenuesTitle: '',
  keyFiguresTitle: '',
}

export default BarCharts
