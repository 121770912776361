import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({palette, breakpoints}) => ({
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  rowContainer: {
    display: 'flex',
    '& > div': {
      display: 'inline',
      flex: 1,
      width: '50%',
      height: '300px',
    },
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      '& > div': {
        width: '100%',
      },
    },
  },
  stockBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > div': {
      width: '80%',
    },
  },
  whiteBackground: {
    backgroundColor: palette.background.default,
  },
  imageStock: {
    width: '100%',
  },
}))

export default useStyles
