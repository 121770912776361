import React from 'react'

import {capitalStructureComponentPropTypes} from 'helpers/propTypes'
import RawText from 'components/UI/RawText'

import useStyles from './styles'

const CapitalStructureTable = ({
  capitalStructureTableHeaders,
  capitalStructureData,
  capitalStructureShareHolders,
}) => {
  const classes = useStyles()

  return (
    <>
      <div className={classes.table}>
        <RawText text={capitalStructureTableHeaders} />
        <div style={{display: 'flex'}}>
          <div style={{order: 2}}>
            <RawText text={capitalStructureData} />
          </div>
          <div>
            <RawText text={capitalStructureShareHolders} />
          </div>
        </div>
      </div>
    </>
  )
}

CapitalStructureTable.propTypes = capitalStructureComponentPropTypes

CapitalStructureTable.defaultProps = {}

export default CapitalStructureTable
