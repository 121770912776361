import * as R from 'ramda'
import {ResponsiveBar} from '@nivo/bar'
import {ResponsivePie} from '@nivo/pie'
import classnames from 'classnames'
import React from 'react'

import {arrayByPropsAscSort, arrayByPropsDscSort} from 'helpers/utils'
import {pieChartsPropTypes} from 'helpers/propTypes'
import RawText from 'components/UI/RawText'
import Title from 'components/UI/Title'

import useIsMobile from 'hooks/useIsMobile'
import useStyles from './styles'

const PieCharts = ({
  keyFiguresBreakdownTitle,
  keyFiguresBreakdownMarketSegmentTitle,
  keyFiguresBreakdownLegend,
  keyFiguresBreakdownIndustryTitle,
  keyFiguresBreakdownGeographicalTitle,
  keyFiguresData,
}) => {
  const classes = useStyles()
  const isMobile = useIsMobile()
  const mapIndexed = R.addIndex(R.map)

  const keyFiguresDataByIndustryAsc = arrayByPropsAscSort(
    ['valueThisYear', 'name'],
    keyFiguresData.byIndustry,
  )

  const keyFiguresDataByIndustryDsc = arrayByPropsDscSort(
    ['valueThisYear', 'name'],
    keyFiguresData.byIndustry,
  )

  const renderSubSectionTitle = () => (
    <div className={classes.subSectionTitle}>
      <Title variant="h3" type="mediumTitle" fontFamily="book">
        {keyFiguresBreakdownTitle}
      </Title>
    </div>
  )

  const renderChartTitle = (label, isCentered) => (
    <Title
      variant="h3"
      type="largeTitle"
      fontFamily="book"
      isCentered={isCentered}
    >
      {label}
    </Title>
  )

  const colors = {
    'Large Corporate': 'hsl(35, 98%, 52%)',
    'Mid Market': 'hsl(211, 100%, 24%)',
    France: 'hsl(211, 100%, 24%)',
    Europe: 'hsl(122, 44%, 47%)',
    'Others  ': 'hsl(195, 100%, 58%)',
    Manufacturing: 'hsl(211, 100%, 24%)',
    'Finance & Insurance': 'hsl(243, 63%, 56%)',
    Services: 'hsl(211, 71%, 46%)',
    Retail: 'hsl(195, 100%, 58%)',
    'Health & Pharma': 'hsl(157, 41%, 32%)',
    'Telecom, Media & Technology': 'hsl(141, 46%, 42%)',
    'Public sector': 'hsl(164, 64%, 55%)',
    'Supply chain': 'hsl(165, 100%, 76%)',
    Utilities: 'hsl(195, 100%, 83%)',
    'Finance / Real Estate': 'hsl(141, 56%, 60%)',
  }
  const getColor = bar => colors[bar.data.name]
  const barTheme = {
    textColor: '#333333',
    fontSize: '12px',
  }

  const renderLegend = (data, isBarCharts) => (
    <ul
      className={classnames(classes.legende, {
        [classes.barChartsLegend]: isBarCharts,
      })}
    >
      {mapIndexed(
        (node, index) => (
          <li>
            <span
              style={{
                display: 'block',
                width: 20,
                backgroundColor: data[index].color,
                height: 18,
                borderRadius: '50%',
                marginRight: 10,
              }}
            />
            <p className={classes.label}>
              <div>
                <span>{node.name}</span>
                <span>
                  {node.valueThisYear}%{' '}
                  <span className={classes.valueLastYear}>
                    ({node.valueLastYear}%)
                  </span>
                </span>
              </div>
            </p>
          </li>
        ),
        data,
      )}
    </ul>
  )

  const renderBarCharts = label => (
    <div className={classes.chartContainerWrap}>
      <div className={classes.chartContainer}>
        {renderChartTitle(label, true)}
        <div className={classes.largeContainer}>
          <ResponsiveBar
            width={isMobile ? 350 : 500}
            height={isMobile ? 350 : 400}
            data={keyFiguresDataByIndustryAsc}
            layout="horizontal"
            keys={['valueThisYear']}
            maxValue={20}
            padding={0.25}
            margin={{
              top: 45,
              bottom: 45,
              right: 25,
              left: isMobile ? 33 : 180,
            }}
            indexBy="name"
            colors={getColor}
            axisLeft={
              !isMobile && {
                tickValues: 8,
              }
            }
            // axisTop={{
            //   tickValues: 7,
            // }}
            axisRight={null}
            enableGridY={false}
            axisBottom={null}
            borderRadius={5}
            label={null}
            tooltip={() => <></>}
            layers={['grid', 'axes', 'bars', 'markers', 'legends']}
            theme={barTheme}
          />
        </div>
        {renderLegend(keyFiguresDataByIndustryDsc, true)}
      </div>
    </div>
  )

  const renderPieChart = (label, data) => (
    <div className={classes.chartContainerWrap}>
      <div className={classes.chartContainer}>
        {renderChartTitle(label, true)}
        <div className={classes.smallContainer}>
          <ResponsivePie
            data={data}
            colors={getColor}
            innerRadius={0.5}
            padAngle={0}
            cornerRadius={0}
            value="valueThisYear"
            activeOuterRadiusOffset={0}
            borderWidth={0}
            enableArcLabels={false}
            enableArcLinkLabels={false}
            margin={{top: 0, right: 30, bottom: 20, left: 30}}
            animate={false}
            tooltip={input => (
              <div className={classes.tooltip}>
                {input.datum.formattedValue}%
              </div>
            )}
          />
        </div>
        {renderLegend(data, false)}
      </div>
    </div>
  )

  return (
    <div>
      {renderSubSectionTitle()}
      <RawText text={keyFiguresBreakdownLegend} />
      <div className={classes.container}>
        <div className={classes.column}>
          {renderPieChart(
            keyFiguresBreakdownMarketSegmentTitle,
            keyFiguresData.byMarketSegment,
          )}
          {renderPieChart(
            keyFiguresBreakdownGeographicalTitle,
            keyFiguresData.byGeographicArea,
          )}
        </div>
        <div className={classes.column}>
          {renderBarCharts(keyFiguresBreakdownIndustryTitle)}
        </div>
      </div>
    </div>
  )
}

PieCharts.propTypes = pieChartsPropTypes

PieCharts.defaultProps = {}

export default PieCharts
