import React from 'react'

import {informationInvestorsPropTypes} from 'helpers/propTypes'
import RawText from 'components/UI/RawText'
import Title from 'components/UI/Title'

import useStyles from './styles'

const Information = ({
  investorsRelationsInfosGeneralMeetings,
  investorsRelationsInfosSecDepartment,
  investorsRelationsInfosTitle,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Title variant="h3" fontFamily="book" type="mediumTitle">
        {investorsRelationsInfosTitle}
      </Title>
      <div className={classes.infoContainer}>
        <div>
          <RawText text={investorsRelationsInfosSecDepartment} />
        </div>
        <div>
          <RawText text={investorsRelationsInfosGeneralMeetings} />
        </div>
      </div>
    </div>
  )
}

Information.propTypes = informationInvestorsPropTypes

Information.defaultProps = {}

export default Information
