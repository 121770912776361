import classnames from 'classnames'
import React from 'react'

import {stockBlockPropTypes} from 'helpers/propTypes'
import RawText from 'components/UI/RawText'
import Title from 'components/UI/Title'

import useIsMobile from 'hooks/useIsMobile'
import useStyles from './styles'

const StockBlock = ({
  keyrusStockTitle,
  keyrusStockImage,
  keyrusStockBlock1,
  keyrusStockBlock2,
  keyrusStockBlock3,
}) => {
  const classes = useStyles()
  const isMobile = useIsMobile()

  return (
    <div>
      <Title hasSmallPaddingBottom type="subTitle" variant="h2">
        {keyrusStockTitle}
      </Title>
      <div className={classes.contentContainer}>
        <div className={classes.rowContainer}>
          <div>
            <img
              src={keyrusStockImage}
              className={classes.imageStock}
              alt=""
              loading="lazy"
            />
          </div>
          <div
            className={classnames(classes.stockBlock, classes.whiteBackground)}
          >
            <div style={{marginLeft: 10}}>
              <RawText text={keyrusStockBlock2} />
            </div>
          </div>
        </div>
        <div className={classes.rowContainer}>
          <div
            className={classnames(
              classes.stockBlock,
              !isMobile && classes.whiteBackground,
            )}
          >
            <div style={{marginLeft: 10}}>
              <RawText text={keyrusStockBlock1} />
            </div>
          </div>
          <div
            className={classnames(
              classes.stockBlock,
              isMobile && classes.whiteBackground,
            )}
          >
            <div style={{marginLeft: 10}}>
              <RawText text={keyrusStockBlock3} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

StockBlock.propTypes = stockBlockPropTypes

StockBlock.defaultProps = {}

export default StockBlock
