import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({breakpoints, spacing, palette}) => ({
  table: {
    fontFamily: 'CamptonBook',
    '& table': {
      width: '100%',
      borderSpacing: spacing(0),
    },
    '& p': {
      margin: 0,
    },
    '& table:nth-child(3) tr:first-child': {
      background: palette.background.contrastGrey,
      height: 70,
      '& p': {
        fontWeight: 'bold',
      },
    },
    '& th': {
      margin: 'auto',
      width: 300,
    },
    '& tr': {
      height: 40,
      textAlign: 'center',
    },
    '& tr:nth-child(even)': {
      background: palette.background.contrastGrey,
    },
    [breakpoints.down('sm')]: {
      '& p': {
        fontSize: 11,
        textAlign: 'center',
        margin: 'auto',
      },
    },
  },
}))

export default useStyles
